import { httpService } from '../../../services';
import { envService } from '../../../services/env.service';
import { protectedApi } from '../decorators';

export const CURRENCIES_QUOTES_URL = '/currencies/quotes';

export class ExchangeRatesResource {
  fetchCurrenciesQuotesCacheKey = 'fetchCurrenciesQuotesCacheKey';

  @protectedApi()
  async fetchCurrenciesQuotes() {
    return await httpService.get<IFetchCurrenciesQuotesResponse>({
      baseURL: `${envService.apiBaseUrl}v2`,
      url: CURRENCIES_QUOTES_URL,
    });
  }
}

export const exchangeRatesResource = new ExchangeRatesResource();
